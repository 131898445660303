.App {
  text-align: center;
}

.app-container {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: #ebebeb;
}

.app-main-content {
  flex: 1;
}

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: app-logo-spin infinite 20s linear;
  }
}

/* HEADER STYLE                      */
.App-header {
  background-color: #005288;
  height: 60px;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-logo {
  height:40px;
  pointer-events: none;
}


.header-left {
  display: flex;
  align-items: center;
}

.header-right {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.dropdown-toggle {
  background: none;
  color:white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: .25rem;
  font-size: 1rem;
  height: 100%;
}

.dropdown-toggle.active-text {
  color: #ec7211; /* Change to desired color when expanded */
}

.dropdown-toggle:hover {
  background: none;
  color: #ec7211;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 4px;
  font-size: 1rem;
  
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background: #ebebeb;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 150px;
  font-size: 11pt;
}

.dropdown-item {
  display: block;
  padding: 0.5rem 1rem;
  color: #333;
  text-decoration: none;
  margin: .25em;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #ffffff;
}
/* Arrow styling */
.arrow-icon {
  margin-left: 0.5rem;
  font-size: 0.8rem;
  transition: transform 0.3s ease; /* Smooth rotation */
}

/* Footer Styles */
.app-footer {
  background-color: #282c34;
  color: white;
  padding: 1em 0;
  text-align: center;
}

.footer-container {
  width: 100%;
  margin: 0 auto;
  padding: 0 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-text {
  margin-bottom: .5em;
}

